import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  headers: {
    'x-api-key': process.env.REACT_APP_PUBLIC_API_KEY,
  },
});

export const getSacramentProgram = async () => {
  return await instance.get('/sacrament-program');
};

export const getAnnouncements = async () => {
  return await instance.get('/announcements');
};
