import { useEffect, useRef, useState } from 'react';
import * as apiService from '../../api';
import DisplayField from '../../components/Shared/DisplayField';
import Header from '../../components/Shared/Header';
import Loader from '../../components/Shared/Loader';
import {
  Hymn,
  HymnType,
  Sacrament,
  Speaker,
  SpeakerType,
} from '../../types/Sacraments';
import formatDateString from '../../utils/formatDateString';
import styles from './styles.module.scss';
import { Card } from '@mui/material';
import { formatHymn } from '../../utils/hymns';
import { userFullName } from '../../utils/users';

const SacramentProgram = () => {
  const [program, setProgram] = useState<Sacrament>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [sequenceList, setSequenceList] = useState<(Speaker | Hymn)[]>([]);
  const loaded = useRef(false);

  useEffect(() => {
    if (!loaded.current) {
      (async () => {
        await refresh();
      })();
    }
  }, []);

  // set sequence list to be speakers and hymns that have sequence numbers
  useEffect(() => {
    if (!loaded.current) {
      const speakers = program?.speakers || [];
      const hymns = program?.hymns || [];

      setSequenceList(
        [...speakers, ...hymns]
          .filter((obj) => obj.sequence)
          .sort((a, b) => a.sequence! - b.sequence!)
      );

      loaded.current = true;
    }
  }, []);

  const refresh = async () => {
    try {
      const response = await apiService.getSacramentProgram();
      const programData = response.data.program;

      if (!programData) {
        setNotFound(!notFound);
      }

      setProgram(programData);

      const speakers = programData?.speakers || [];
      const hymns = programData?.hymns || [];

      setSequenceList(
        [...speakers, ...hymns]
          .filter((obj) => obj.sequence)
          .sort((a, b) => a.sequence! - b.sequence!)
      );
    } catch (error: any) {
      console.log(`🚨🚨🚨🚨🚨🚨 Error: ${error.message}`);
    }
  };

  // NOT FOUND
  if (notFound) {
    return (
      <>
        <Header
          title='Sacrament Program'
          direction='row'
          type='page'
          underlined
        />

        <div>Program Not Found</div>
      </>
    );
  } else if (!program) {
    // LOADING
    return (
      <>
        <Header
          title='Sacrament Program'
          direction='row'
          type='page'
          underlined
        />
        <Loader />;
      </>
    );
  }

  return (
    <div className={styles.container}>
      <Header
        title='Sacrament Program'
        direction='row'
        type='page'
        underlined
      />

      <Card className={styles.card}>
        {/* DATE */}
        <div className={styles.field}>
          <DisplayField
            label='Date'
            value={formatDateString(program?.date || '')}
          />
        </div>

        {/* PRESIDING */}
        <div className={styles.field}>
          <DisplayField label='Presiding' value={program?.presiding} />
        </div>

        {/* CONDUCTING */}
        <div className={styles.field}>
          <DisplayField label='Conducting' value={program?.conducting} />
        </div>

        {/* OPENING HYMN */}
        <div className={styles.field}>
          <DisplayField label='Opening Hymn' value={program?.openingHymn} />
        </div>

        {/* INVOCATION */}
        <div className={styles.field}>
          <DisplayField label='Invocation' value={program?.invocation} />
        </div>

        {/* CHORISTER */}
        <div className={styles.field}>
          <DisplayField label='Chorister' value={program?.chorister} />
        </div>

        {/* ORGANIST */}
        <div className={styles.field}>
          <DisplayField label='Organist' value={program?.organist} />
        </div>

        {/* SACRAMENT HYMN */}
        <div className={styles.field}>
          <DisplayField label='Sacrament Hymn' value={program?.sacramentHymn} />
        </div>

        {/* PASSING OF THE SACRAMENT */}
        <div className={styles.field}>
          <Header
            title='Administration of the Sacrament'
            direction='row'
            type='section'
            bold
          />
        </div>

        {/* PROGRAM */}
        {program?.isFastAndTestimony ? (
          <div>
            <div className={styles.field}>
              <DisplayField label='Testimony' value={program?.conducting} />
            </div>

            <div className={styles.field}>
              <DisplayField label='Testimonies' value='Ward Members' />
            </div>
          </div>
        ) : sequenceList.length ? (
          sequenceList.map((obj, index) => {
            const objType: any = obj.type;
            const isHymn = Object.values(HymnType).includes(objType);
            const isSpeaker = Object.values(SpeakerType).includes(objType);

            const hymnLabelMap: any = {
              [HymnType.INTERMEDIATE]: 'Intermediate Hymn',
              [HymnType.MUSICAL_NUMBER]: 'Musical Number',
              [HymnType.CONGREGATIONAL]: 'Congregational Hymn',
            };

            const speakerLabelMap: any = {
              [SpeakerType.TALK]: 'Speaker',
              [SpeakerType.READING]: 'Reading',
            };

            // HYMNS + MUSICAL NUMBERS
            if (isHymn) {
              return (
                <div
                  key={index}
                  className={`${styles.field} ${styles.intermediateHymn}`}
                >
                  <DisplayField
                    label={hymnLabelMap[obj.type]}
                    value={formatHymn(obj as Hymn)}
                  />
                </div>
              );
            } else if (isSpeaker) {
              // SPEAKERS
              return (
                <div className={styles.field}>
                  <DisplayField
                    label={speakerLabelMap[obj.type]}
                    key={index}
                    value={(obj as Speaker).name}
                  />
                </div>
              );
            }
          })
        ) : (
          <div className={styles.field}>
            <DisplayField label='Speakers' />
          </div>
        )}

        {/* CLOSING HYMN */}
        <div className={styles.field}>
          <DisplayField label='Closing Hymn' value={program?.closingHymn} />
        </div>

        {/* BENEDICTION */}
        <div className={styles.field}>
          <DisplayField label='Benediction' value={program?.benediction} />
        </div>
      </Card>
    </div>
  );
};

export default SacramentProgram;
