import { IconButton, Tooltip } from '@mui/material';
import styles from './styles.module.scss';
import { ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  onClick: Function;
  tooltip: string;
  condition: boolean | undefined;
  color?: any;
};

const IconOnlyButton = (props: Props) => {
  return (
    <>
      {props.condition && (
        <Tooltip title={props.tooltip}>
          <IconButton
            onClick={() => props.onClick()}
            className={`${styles.iconButton}`}
            color={props.color}
          >
            {props.icon}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default IconOnlyButton;
