import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../components/Shared/Layout';
import Announcements from '../pages/Announcements';
import BuildingMap from '../pages/BuildingMap';
import Home from '../pages/Home';
import MeetingSchedule from '../pages/MeetingSchedule';
import EldersQuorum from '../pages/Organizations/EldersQuorum';
import FamilyHistory from '../pages/Organizations/FamilyHistory';
import Primary from '../pages/Organizations/Primary';
import ReliefSociety from '../pages/Organizations/ReliefSociety';
import SundaySchool from '../pages/Organizations/SundaySchool';
import WardMission from '../pages/Organizations/WardMission';
import YoungMen from '../pages/Organizations/YoungMen';
import YoungWomen from '../pages/Organizations/YoungWomen';
import PageNotFound from '../pages/PageNotFound';
import Sacrament from '../pages/Sacrament';
import { RouteData } from './types';

// NAV ROUTES
export const navRoutes: RouteData[] = [
  {
    name: 'Home',
    path: '/home/*',
    showInNav: true,
    children: [
      {
        path: '/',
        element: (
          <Layout>
            <Home />
          </Layout>
        ),
      },
    ],
  },
  {
    name: 'Sacrament Program',
    path: '/sacrament-program/*',
    showInNav: true,
    children: [
      {
        path: '/',
        element: (
          <Layout>
            <Sacrament />
          </Layout>
        ),
      },
    ],
  },
  {
    name: 'Meeting Schedule',
    path: '/meeting-schedule/*',
    showInNav: true,
    children: [
      {
        path: '/',
        element: (
          <Layout>
            <MeetingSchedule />
          </Layout>
        ),
      },
    ],
  },
  {
    name: 'Announcements',
    path: '/announcements/*',
    showInNav: true,
    children: [
      {
        path: '/',
        element: (
          <Layout>
            <Announcements />
          </Layout>
        ),
      },
    ],
  },
  {
    name: 'Building Map',
    path: '/building-map/*',
    showInNav: false,
    children: [
      {
        path: '/',
        element: (
          <Layout>
            <BuildingMap />
          </Layout>
        ),
      },
    ],
  },
  {
    name: 'Organizations',
    path: 'organizations/*',
    showInNav: false, // temporary until finished
    accordion: true,
    children: [
      {
        path: 'elders-quorum',
        element: (
          <Layout>
            <EldersQuorum />
          </Layout>
        ),
        accordionName: 'Elders Quorum',
      },
      {
        path: 'family-history',
        element: (
          <Layout>
            <FamilyHistory />
          </Layout>
        ),
        accordionName: 'Family History',
      },
      {
        path: 'primary',
        element: (
          <Layout>
            <Primary />
          </Layout>
        ),
        accordionName: 'Primary',
      },
      {
        path: 'relief-society',
        element: (
          <Layout>
            <ReliefSociety />
          </Layout>
        ),
        accordionName: 'Relief Society',
      },
      {
        path: 'sunday-school',
        element: (
          <Layout>
            <SundaySchool />
          </Layout>
        ),
        accordionName: 'Sunday School',
      },
      {
        path: 'ward-mission',
        element: (
          <Layout>
            <WardMission />
          </Layout>
        ),
        accordionName: 'Ward Mission',
      },
      {
        path: 'young-men',
        element: (
          <Layout>
            <YoungMen />
          </Layout>
        ),
        accordionName: 'Young Men',
      },
      {
        path: 'young-women',
        element: (
          <Layout>
            <YoungWomen />
          </Layout>
        ),
        accordionName: 'Young Women',
      },
    ],
  },
];

// HIDDEN ROUTES
export const hiddenRoutes: RouteData[] = [
  {
    path: '/*',
    showInNav: false,
    children: [
      {
        path: '/',
        element: <Navigate to='/home' />,
      },
    ],
  },
];

// ALL ROUTES
const routes = [...hiddenRoutes, ...navRoutes];

// add page not found to each top level route
for (const route of routes) {
  route.children.push({
    path: '/*',
    element: <PageNotFound />,
  });
}

// ROUTER
const router = (
  <Routes>
    {routes.map((route, index) => {
      return (
        <Route
          key={index}
          path={route.path}
          element={
            <Routes>
              {route.children.map((child, index) => {
                return (
                  <Route
                    key={index}
                    path={child.path}
                    element={child.element}
                  />
                );
              })}
            </Routes>
          }
        />
      );
    })}
  </Routes>
);

export default router;
