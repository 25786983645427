import { ReactNode } from 'react';
import styles from './styles.module.scss';
import NavBar from '../NavBar';

type Props = {
  children: ReactNode;
};

const Layout = (props: Props) => {
  return (
    <div className={styles.container}>
      <div>
        <NavBar />
      </div>
      <div className={styles.children}>{props.children}</div>
    </div>
  );
};

export default Layout;
