import { Card } from '@mui/material';
import Header from '../../../components/Shared/Header';
import styles from './styles.module.scss';

const FamilyHistory = () => {
  return (
    <div className={styles.container}>
      <Header title='Family History' direction='row' type='page' underlined />

      <div className={styles.section}>
        <Header title='Announcements' direction='row' type='section' />
        <Card className={styles.card} />
      </div>

      <div className={styles.section}>
        <Header title='Activities' direction='row' type='section' />
        <Card className={styles.card} />
      </div>
    </div>
  );
};

export default FamilyHistory;
