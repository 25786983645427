import { Link } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Linkify from 'react-linkify';
import { getAnnouncements } from '../../api';
import Header from '../../components/Shared/Header';
import Loader from '../../components/Shared/Loader';
import { Announcement } from '../../types/Sacraments';
import styles from './styles.module.scss';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const loaded = useRef(false);

  useEffect(() => {
    if (!loaded.current) {
      (async () => await refresh())();
      loaded.current = true;
    }
  }, []);

  const refresh = async () => {
    try {
      const response = await getAnnouncements();
      setAnnouncements(response.data.announcements);
    } catch (error: any) {
      console.log(`🚨🚨🚨🚨🚨🚨 Error: ${error.message}`);
    }
  };

  const CustomLinkDecorator = (href: string, text: string, key: number) => {
    return (
      <Link key={key} href={href} target='_blank' rel='noopener noreferrer'>
        {text}
      </Link>
    );
  };

  if (!loaded.current) {
    return (
      <>
        <Header title='Announcements' direction='row' type='page' underlined />
        <Loader />;
      </>
    );
  }

  return (
    <div className={styles.container}>
      <Header title='Announcements' direction='row' type='page' underlined />

      {announcements.length ? (
        <div>
          {announcements.map((announcement, index) => {
            return (
              <div key={index} className={styles.announcement}>
                <div className={styles.subject}>{announcement.subject}</div>
                <div className={styles.description}>
                  <Linkify componentDecorator={CustomLinkDecorator}>
                    {announcement.description}
                  </Linkify>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>No Announcements Found</div>
      )}
    </div>
  );
};

export default Announcements;
