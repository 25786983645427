import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { navRoutes } from '../../../router';
import styles from './styles.module.scss';

const NavBar = () => {
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const permanentDrawer = width >= 800;
  const drawerVariant = permanentDrawer ? 'permanent' : 'temporary';

  useEffect(() => {
    setShow(false);
  }, [width]);

  const handleDrawerToggle = () => {
    setShow(!show);
  };

  const logoClicked = () => {
    navigate('/home');
    handleDrawerToggle();
  };

  return (
    <>
      <IconButton onClick={handleDrawerToggle} className={styles.menuButton}>
        <MenuIcon fontSize='large' />
      </IconButton>

      <Drawer variant={drawerVariant} open={show} onClose={handleDrawerToggle}>
        <div className={styles.container}>
          <div className={styles.header}>
            <img
              src='/images/logo.png'
              alt='Illustration of the Payson Temple'
              className={styles.logo}
              onClick={logoClicked}
            />
            <div className={styles.name}>Summit Creek Ward</div>
          </div>

          {navRoutes
            .filter((route) => route.showInNav)
            .map((route, index) => {
              if (route.accordion) {
                return (
                  <Accordion className={styles.accordion} key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {route.name}
                    </AccordionSummary>
                    <AccordionDetails>
                      {route.children
                        .filter((child) => child.accordionName)
                        .map((child, index) => {
                          return (
                            <NavLink
                              to={`/${route.path}/${child.path.replace(
                                '/*',
                                ''
                              )}`.replaceAll('/*', '')}
                              className={(route) => {
                                return route.isActive
                                  ? `${styles.link} ${styles.active}`
                                  : `${styles.link}`;
                              }}
                              key={index}
                              onClick={handleDrawerToggle}
                            >
                              <span>{child.accordionName}</span>
                            </NavLink>
                          );
                        })}
                    </AccordionDetails>
                  </Accordion>
                );
              }

              return (
                <NavLink
                  to={route.path.replace('/*', '')}
                  className={(route) => {
                    return route.isActive
                      ? `${styles.link} ${styles.active}`
                      : `${styles.link}`;
                  }}
                  key={index}
                  onClick={handleDrawerToggle}
                >
                  <span>{route.name}</span>
                </NavLink>
              );
            })}
        </div>
      </Drawer>
    </>
  );
};

export default NavBar;
