import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import styles from './styles.module.scss';

type Props = {
  rows: readonly any[];
};

const BuildingMapGrid = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { width, height } = useWindowDimensions();
  const hide = width < 1000;

  const columns: GridColDef[] = [
    {
      field: 'group',
      headerName: 'Group',
      sortable: false,
      hideable: false,
      resizable: false,
      flex: 1,
    },
    {
      field: 'first',
      headerName: '1st Sunday',
      sortable: false,
      hideable: false,
      resizable: false,
      flex: 1,
    },
    {
      field: 'second',
      headerName: '2nd Sunday',
      sortable: false,
      hideable: false,
      resizable: false,
      flex: 1,
    },
    {
      field: 'third',
      headerName: '3rd Sunday',
      sortable: false,
      hideable: false,
      resizable: false,
      flex: 1,
    },
    {
      field: 'fourth',
      headerName: '4th Sunday',
      sortable: false,
      hideable: false,
      resizable: false,
      flex: 1,
    },
    {
      field: 'fifth',
      headerName: '5th Sunday',
      sortable: false,
      hideable: false,
      resizable: false,
      flex: 1,
    },
  ];

  if (hide) {
    return <></>;
  }

  return (
    <Box className={styles.container}>
      <DataGrid
        rows={props.rows}
        rowSelection={false}
        columns={columns}
        loading={loading}
        // slots={{ noRowsOverlay, loadingOverlay }}
        // getRowClassName={(params) => props.getRowClassName?.(params)}
        // onRowClick={(params) => props.onRowClick?.(params.id)}
        sx={{
          height: height - 125,
          borderRadius: '10px',
          padding: '1px',
          '.MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          // backgroundColor: theme.palette.background.paper,
          // border: `solid 2px ${theme.palette.grey[300]}`,
          // '.MuiDataGrid-cell': {
          //   cursor: 'pointer',
          // },
        }}
        disableColumnMenu
      />
    </Box>
  );
};

export default BuildingMapGrid;
