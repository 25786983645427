import { Hymn } from '../types/Sacraments';

export const formatHymn = (hymn?: Hymn) => {
  if (!hymn) {
    return;
  }

  if (hymn.performedBy) {
    return `${hymn.performedBy} - ${hymn.title}`;
  }

  if (!hymn.number) {
    return `${hymn.title}`;
  }

  return `#${hymn.number} - ${hymn.title}`;
};
