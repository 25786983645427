import FilterIcon from '@mui/icons-material/FilterAlt';
import UnfilterIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { DropdownMenuOption } from '../../../types/shared';
import DropdownMenu from '../DropdownMenu';
import IconOnlyButton from '../IconOnlyButton';
import styles from './styles.module.scss';

type Props = {
  type: 'page' | 'section';
  direction: 'row' | 'column';
  underlined?: boolean;
  bold?: boolean;
  title: string;
  subtitle?: string;
  menuOptions?: DropdownMenuOption[];
  isForm?: boolean;
  onSubmit?: Function;
  onCancel?: Function;
  canEdit?: boolean;
  onEdit?: Function;
  canCreate?: boolean;
  onCreate?: Function;
  canFilter?: boolean;
  filtered?: boolean;
  onFilter?: Function;
  canRefresh?: boolean;
  onRefresh?: Function;
  canComplete?: boolean;
  onComplete?: Function;
  isComplete?: boolean;
  canDelete?: boolean;
  onDelete?: Function;
};

const Header = (props: Props) => {
  const { width, height } = useWindowDimensions();

  return (
    <div
      className={`${styles.container} 
      ${props.underlined && styles.underlined}
      ${props.direction === 'column' ? styles.column : styles.row}
      `}
    >
      {props.type === 'page' ? (
        <h1 className={props.bold ? styles.bold : ''}>{props.title}</h1>
      ) : (
        <div
          className={`${styles.sectionHeader} ${props.bold ? styles.bold : ''}`}
        >
          {props.title}
        </div>
      )}

      {props.subtitle && <h3>{props.subtitle}</h3>}

      <div className={styles.buttons}>
        {/* REFRESH BUTTON */}
        <IconOnlyButton
          icon={<RefreshIcon />}
          onClick={() => props.onRefresh?.()}
          tooltip='Refresh'
          condition={props.canRefresh}
        />

        {/* FILTER BUTTON */}
        <IconOnlyButton
          icon={props.filtered ? <UnfilterIcon /> : <FilterIcon />}
          onClick={() => props.onFilter?.()}
          tooltip='Hide Completed'
          condition={props.canFilter || props.filtered || false}
        />

        {/* MENU BUTTON */}
        {!props.isForm && props.menuOptions && (
          <DropdownMenu options={props.menuOptions} />
        )}
      </div>

      {process.env.NODE_ENV === 'development' ? (
        <div className={styles.width}>
          {width} x {height}
        </div>
      ) : null}
    </div>
  );
};

export default Header;
