import { ScaleLoader } from 'react-spinners';
import styles from './styles.module.scss';

const Loader = () => {
  return (
    <div className={styles.loaderContainer}>
      <ScaleLoader />
    </div>
  );
};

export default Loader;
