import { Calling } from '../Callings';

export enum SacramentStatus {
  NEEDS_INFO = 'NEEDS_INFO',
  COMPLETE = 'COMPLETE',
}

export type Sacrament = {
  date: string;
  presiding: string;
  conducting: string;
  isFastAndTestimony: boolean;
  openingHymn: string;
  invocation: string;
  chorister: string;
  organist: string;
  sacramentHymn: string;
  hymns: Hymn[];
  speakers: Speaker[];
  closingHymn: string;
  benediction: string;
};

export type Announcement = {
  _id: string;
  createdDate: string;
  startDate: string | null;
  endDate: string | null;
  subject: string;
  description?: string;
};

export enum HymnType {
  OPENING = 'OPENING',
  SACRAMENT = 'SACRAMENT',
  INTERMEDIATE = 'INTERMEDIATE',
  MUSICAL_NUMBER = 'MUSICAL_NUMBER',
  CONGREGATIONAL = 'CONGREGATIONAL',
  CLOSING = 'CLOSING',
}

export type Hymn = {
  number: number;
  title: string;
  date: string;
  type: HymnType;
  performedBy?: string;
  sequence?: number;
};

export enum PrayerType {
  INVOCATION = 'INVOCATION',
  BENEDICTION = 'BENEDICTION',
}

export type Prayer = {
  name: string;
  type: PrayerType;
};

export enum SpeakerType {
  TALK = 'TALK',
  READING = 'READING',
}

export type Speaker = {
  name: string;
  sequence: number;
  type: SpeakerType;
};

export type Music = {
  chorister: string;
  organist: string;
};

export type CallingChanges = {
  name: string;
  calling: Calling;
  _id: string;
};

export type SacramentOther = {
  name: string;
  performedBy: string;
};

export enum WardBusinessType {
  SUSTAINING = 'SUSTAINING',
  RELEASE = 'RELEASE',
  BABY_BLESSING = 'BABY_BLESSING',
  CONFIRMATION = 'CONFIRMATION',
}

export type WardBusiness = {
  date: string;
  type: WardBusinessType;
  name: string;
  calling?: Calling;
  performedBy?: string;
};
