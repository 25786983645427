import { Box } from '@mui/material';
import BuildingMapCard from './BuildingMapCard';
import styles from './styles.module.scss';

type Props = {
  rows: readonly any[];
};

const BuildingMapCards = (props: Props) => {
  return (
    <Box className={styles.container}>
      {props.rows.map((data) => {
        return <BuildingMapCard data={data} />;
      })}
    </Box>
  );
};

export default BuildingMapCards;
