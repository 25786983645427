import { Box } from '@mui/material';
import Header from '../../components/Shared/Header';
import styles from './styles.module.scss';
import BuildingMapGrid from './components/BuildingMapGrid';
import BuildingMapCards from './components/BuildingMapCards';

const BuildingMap = () => {
  const data: readonly any[] = [
    {
      id: 1,
      group: '1-2 Girls/Boys',
      first: '107/108',
      second: '107/108',
      third: '107/108',
      fourth: '107/108',
      fifth: '107/108',
    },
    {
      id: 2,
      group: '3 Girls/Boys',
      first: '112/115',
      second: '112/115',
      third: '112/115',
      fourth: '112/115',
      fifth: '112/115',
    },
    {
      id: 3,
      group: '4 Girls/Boys',
      first: '106/104',
      second: '106/104',
      third: '106/104',
      fourth: '106/104',
      fifth: '106/104',
    },
    {
      id: 4,
      group: '5 Girls/Boys',
      first: '101/116',
      second: '101/116',
      third: '101/116',
      fourth: '101/116',
      fifth: '101/116',
    },
    {
      id: 5,
      group: '6 Girls/Boys',
      first: '112/115',
      second: '112/115',
      third: '112/115',
      fourth: '112/115',
      fifth: '112/115',
    },
    {
      id: 6,
      group: '7 Girls/Boys',
      first: '106/111',
      second: '106/111',
      third: '106/111',
      fourth: '106/111',
      fifth: '106/111',
    },
    {
      id: 7,
      group: '8 Girls/Boys',
      first: '104',
      second: '104',
      third: '104',
      fourth: '104',
      fifth: '104',
    },
    {
      id: 8,
      group: '9 Girls/Boys',
      first: '101',
      second: '101',
      third: '101',
      fourth: '101',
      fifth: '101',
    },
    {
      id: 9,
      group: '10 Girls/Boys',
      first: '116',
      second: '116',
      third: '116',
      fourth: '116',
      fifth: '116',
    },
    {
      id: 10,
      group: '11-12 Girls',
      first: '102',
      second: '103/105',
      third: '102',
      fourth: '103/105',
      fifth: '102',
    },
    {
      id: 11,
      group: '13-14 Girls',
      first: '105',
      second: '103/105',
      third: '105',
      fourth: '103/105',
      fifth: '105',
    },
    {
      id: 12,
      group: '15-16 Girls',
      first: 'Bishop',
      second: '103/105',
      third: 'Bishop',
      fourth: '103/105',
      fifth: 'Bishop',
    },
    {
      id: 13,
      group: '17-18 Girls',
      first: 'Bishop',
      second: '103/105',
      third: 'Bishop',
      fourth: '103/105',
      fifth: 'Bishop',
    },
    {
      id: 14,
      group: '11-12 Boys',
      first: '102',
      second: '107',
      third: '102',
      fourth: '107',
      fifth: '102',
    },
    {
      id: 15,
      group: '13-14 Boys',
      first: '105',
      second: '102',
      third: '105',
      fourth: '102',
      fifth: '105',
    },
    {
      id: 16,
      group: '15-16 Boys',
      first: 'Bishop',
      second: 'Bishop',
      third: 'Bishop',
      fourth: 'Bishop',
      fifth: 'Bishop',
    },
    {
      id: 17,
      group: '17-18 Boys',
      first: 'Bishop',
      second: 'Bishop',
      third: 'Bishop',
      fourth: 'Bishop',
      fifth: 'Bishop',
    },
    {
      id: 18,
      group: 'Adult Men',
      first: 'PL/RS',
      second: 'Gym/HC',
      third: 'PL/RS',
      fourth: 'Gym/HC',
      fifth: 'PL/RS',
    },
    {
      id: 19,
      group: 'Adult Women',
      first: 'PL/RS',
      second: 'RS/PL',
      third: 'PL/RS',
      fourth: 'RS/PL',
      fifth: 'PL/RS',
    },
  ];

  return (
    <Box className={styles.container}>
      <Header type='page' direction='row' title='Building Map' underlined />
      <BuildingMapGrid rows={data} />
      <BuildingMapCards rows={data} />
    </Box>
  );
};

export default BuildingMap;
