import { Box, Card } from '@mui/material';
import DisplayField from '../../../../../components/Shared/DisplayField';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import styles from './styles.module.scss';

type Props = {
  data: any;
};

const BuildingMapCard = (props: Props) => {
  const { width } = useWindowDimensions();
  const hide = width >= 1000;

  if (hide) {
    return <></>;
  }

  return (
    <Card className={styles.container}>
      <DisplayField label='Group' value={props.data.group} />
    </Card>
  );
};

export default BuildingMapCard;
