import styles from './styles.module.scss';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { DropdownMenuOption } from '../../../types/shared';

type Props = {
  options: DropdownMenuOption[];
};

const DropdownMenu = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.container}>
      {/* MENU BUTTON */}
      <Tooltip title='More'>
        <IconButton onClick={handleClick}>
          <MoreHorizIcon className={styles.button} />
        </IconButton>
      </Tooltip>

      {/* DROPDOWN */}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.options.map((option, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                option.onClick();
                handleClose();
              }}
              className={styles.menuItem}
              style={{ color: option.color }}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
