import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { ReactNode } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type Props = {
  label?: string;
  value?: string | string[];
  isLink?: boolean;
  to?: string;
  children?: ReactNode;
  isList?: boolean;
};

const DisplayField = (props: Props) => {
  return (
    <div className={styles.container}>
      {/* LABEL */}
      {props.label && <div className={styles.label}>{props.label}</div>}

      {/* LIST */}
      {props.isList &&
        (props.value as string[]).map((entry, index) => {
          return <div key={index}>{entry}</div>;
        })}

      {/* LINK */}
      {!props.isList && !props.children && props.isLink ? (
        <Link
          to={props.to || ''}
          className={`${!props.label && styles.noLabel} ${styles.link}`}
        >
          {props.value}
          <KeyboardArrowRightIcon />
        </Link>
      ) : (
        <div className={styles.value}>
          {props.children ? '' : props.value || 'Not Set'}
        </div>
      )}

      {/* CHILDREN */}
      {props.children ? <div>{props.children}</div> : null}
    </div>
  );
};

export default DisplayField;
